.ham-container {
    height: 100%;
    display: flex;
    flex-direction: column;

}

.ham-nav-menu {
    background-color: #fefae0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1;
    text-align: right;
}

.ham-nav-menu.show-menu {
    transform: translateX(0);
}

.ham-nav-menu button {
    width: 50%;
    height: 40px;
}

.ham-navbar {
    position: fixed;
    width: 100%;
    right: 0;
    height: 10vh;
    background-color: #fefae0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    gap: 10px;
    text-align: right;
    z-index: 0;
}

.ham-navbar button {
    height: 100%;
    padding: 10px;
    cursor: pointer;

}

.ham-nav-links ul {
    text-align: right;
    height: auto;
    
}

.ham-nav-links li {
    padding: 5px;
    height: auto;
    font-size: 2rem;
}

.ham-nav-links i {
    font-size: 2rem;
}

.ham-nav-links a {
    text-decoration: none;
    color: black;
}